<template>
  <q-page class="q-pa-none row justify-center">
    <q-card style="max-width: 600px; width: 600px" flat>
      <q-card-section style="height: 100%">
        <iframe
          :src="source"
          frameborder="0"
          width="100%"
          height="600px"
        ></iframe>
      </q-card-section>
    </q-card>
  </q-page>
</template>

<script>
import { defineComponent, ref, onMounted, computed, watch } from "vue";
//import api from "../api/index";
import { useStore } from "vuex";
//import general from "../mixins/general";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "DataEntry",
  setup() {
    const store = useStore();
    const route = useRoute();
    const show = ref(true);
    const title = ref("Data Entry");

    //const { NotifyUser, getMoment } = general();

    const iType = ref(null);

    const _type = computed(() => route.query.type);
    const _title = computed(() => route.query.title);

    watch([_type, _title], ([e, f]) => {
      iType.value = e;
      title.value = f;
      UpdateTitle();
    });

    const source = computed(() => {
      var _t =
        iType.value !== null && iType.value !== undefined
          ? `&type=${iType.value}`
          : "";
      return `https://script.google.com/macros/s/AKfycbwb-LxEZSv0mJp7LnhpyqtzKXu0r6VW9uJBTH3_85RRv3EiRwE/exec?showheader=0${_t}`;
    });

    const UpdateTitle = () => {
      store.dispatch("UpdateHeader", title.value);
    };

    onMounted(() => {
      iType.value = route.query.type;
      title.value = route.query.title;
      UpdateTitle();
    });

    return {
      show,
      source,
    };
  },
});
</script>
